import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/Layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`// import { graphql } from "gatsby";
// import Layout from "../../../components/Layout";
// import NoteCard from "../../../components/NoteCard";
// import EssayCard from "../../../components/EssayCard";
// import Card from "../../../components/Card";`}</p>
    {
      /* { 2 + 2 }
      <Card animate="true" className="min-h-64 w-full">
       {props.pageContext.frontmatter.cover_image && (
         <img
           src={props.pageContext.frontmatter.cover_image}
           className="h-full w-full object-cover min-h-80"
         />
       )}
       <div className="px-3 pb-4 pt-12 absolute bottom-0 w-full bg-gradient-to-t from-black text-white">
         <p className="font-semibold text-xl indent-0 leading-tight mb-1">
           {props.pageContext.frontmatter.title}
         </p>
         <p className="indent-0 leading-5 text-base font-medium">
           {props.pageContext.frontmatter.subtitle}
         </p>
       </div>
      </Card>
      This is _some markdown_
      <NoteCard note={props.data.notes.nodes[0]} />
      This is _some markdown_
      <EssayCard post={props.data.essays.nodes[0]} isLinked mini />
      export const pageQuery = graphql`
       query {
         notes: allBrainNote(
           filter: {
             title: {
               in: ["Critique is important, but not always from clients.", "MOOCs"]
             }
           }
         ) {
           nodes {
             id
             title
             childMdx {
               frontmatter {
                 title
                 tags
                 date(formatString: "MM.DD.YYYY")
               }
             }
           }
         }
         essays: allMdx(
           filter: {
             fileAbsolutePath: { regex: "/content/essays/" }
             frontmatter: { title: { in: "Algorithmic Sovereignity and Datapower" } }
           }
           sort: { fields: frontmatter___date }
           limit: 4
         ) {
           nodes {
             ...EssayPreview
           }
         }
       }
      `; */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      